/*
 * @Description:
 * @Version: 1.0
 * @Autor: hs
 * @Date: 2021-10-11 09:59:07
 * @LastEditors: hs
 * @LastEditTime: 2021-10-14 16:41:41
 */
import {
  MUTATIONS_PARK3D__ENVIRONMENT_DEVICE_COUNT,
  MUTATIONS_PARK3D__ENVIRONMENT_GROUP_COUNT,
  MUTATIONS_PARK3D__ENVIRONMENT_CHANGE,
  MUTATIONS_PARK3D__ENVIRONMENT_ALARM,
  MUTATIONS_PARK3D__ENVIRONMENT_FACILITYLIST,
  MUTATIONS_PARK3D__ENVIRONMENT_DETECTIONINFO
} from '../mutations_type'

const environmentData = {
  state: () => {
    return {
      parkenvironmentDeviceCount: {
        deviceCount: 0,
        faultDevice: 0,
        onlineDevice: 0,
        warningDevice: 0
      },
      parkenvironmentGroupCount: [],
      parkenvironmentChange: {
        humidityMonth: [],
        hourNewPM25Month: [],
        hourRainMonth: [],
        outTemperatureMonth: [],
        speedMonth: [],
        hourNewPM10Month: [],
        hourNoiseMonth: [],
        humidityDay: [],
        hourNewPM25Day: [],
        hourRainDay: [],
        outTemperatureDay: [],
        speedDay: [],
        hourNewPM10Day: [],
        hourNoiseDay: []
      },
      parkenvironmentAlarm: [],
      parkenvironmentFacilityList: [],
      parkenvironmentDetectionInfo: []
    }
  },
  mutations: {
    // 设备总数
    [MUTATIONS_PARK3D__ENVIRONMENT_DEVICE_COUNT](state, val) {
      console.log('设备数', val)
      state.parkenvironmentDeviceCount = val
    },
    // 环境设备图表数据
    [MUTATIONS_PARK3D__ENVIRONMENT_GROUP_COUNT](state, val) {
      console.log('环境设备图表数据', val)
      state.parkenvironmentGroupCount = val
    },
    // 环境变化趋势
    [MUTATIONS_PARK3D__ENVIRONMENT_CHANGE](state, val) {
      console.log('环境变化趋势', val)
      state.parkenvironmentChange = val
    },
    // 环境告警数据
    [MUTATIONS_PARK3D__ENVIRONMENT_ALARM](state, val) {
      console.log('环境告警数据', val)
      state.parkenvironmentAlarm = val
    },
    // 环境设备列表
    [MUTATIONS_PARK3D__ENVIRONMENT_FACILITYLIST](state, val) {
      console.log('环境设备列表', val)
      state.parkenvironmentFacilityList = val
    },
    // 环境检测信息
    [MUTATIONS_PARK3D__ENVIRONMENT_DETECTIONINFO](state, val) {
      console.log('环境检测信息', val)
      state.parkenvironmentDetectionInfo = val
    }
  },
  actions: {
    fn_api_get_park_environmentDeviceCount({ commit }, data) {
      commit(MUTATIONS_PARK3D__ENVIRONMENT_DEVICE_COUNT, data)
    },
    fn_api_get_park_environmentGroupCount({ commit }, data) {
      commit(MUTATIONS_PARK3D__ENVIRONMENT_GROUP_COUNT, data)
    },
    fn_api_get_park_environmentChange({ commit }, data) {
      commit(MUTATIONS_PARK3D__ENVIRONMENT_CHANGE, data)
    },
    fn_api_get_park_environmentAlarmList({ commit }, data) {
      commit(MUTATIONS_PARK3D__ENVIRONMENT_ALARM, data)
    },
    fn_api_get_park_environmentFacilityList({ commit }, data) {
      commit(MUTATIONS_PARK3D__ENVIRONMENT_FACILITYLIST, data)
    },
    fn_api_get_park_environmentDetectionInfo({ commit }, data) {
      commit(MUTATIONS_PARK3D__ENVIRONMENT_DETECTIONINFO, data)
    }
  }
}

export default environmentData
