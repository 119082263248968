/*
 * @Description:
 * @Version: 1.0
 * @Autor: hs
 * @Date: 2021-07-29 16:43:32
 * @LastEditors: hs
 * @LastEditTime: 2021-10-14 16:15:20
 */
if (window.__POWERED_BY_QIANKUN__) {
  // 静态 publicPath 配置 处理资源
  __webpack_public_path__ = window.__INJECTED_PUBLIC_PATH_BY_QIANKUN__
}
