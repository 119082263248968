/*
 * @Description:
 * @Version: 1.0
 * @Autor: hs
 * @Date: 2021-07-12 13:49:10
 * @LastEditors: hs
 * @LastEditTime: 2021-10-15 13:54:03
 */
import Vue from 'vue'
import VueRouter from 'vue-router'
import ViewsRoutes from './views/index'
import PagesRoutes from './pages/index'
// 解决 vue-router 3.0+ 跳转相同地址会报错误的问题
const ROUTER_PUSH = VueRouter.prototype.push
VueRouter.prototype.push = function(location, onComplete, onAbort) {
  const temp = ROUTER_PUSH.call(this, location, onComplete, onAbort)
  if (temp) {
    return temp.catch(() => mfDemo.resetScene())
  }
}
Vue.use(VueRouter)

const routes = [...ViewsRoutes, ...PagesRoutes]
// console.log('routes', routes)
const router = new VueRouter({
  mode: 'hash',
  // base: process.env.BASE_URL,
  base: window.__POWERED_BY_QIANKUN__ ? '/smart-3d' : '/',
  routes
})

export default router
