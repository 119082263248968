/*
 * @Description:
 * @Version: 1.0
 * @Autor: hs
 * @Date: 2021-07-12 14:07:59
 * @LastEditors: hs
 * @LastEditTime: 2021-10-14 16:42:28
 */

export default [
  {
    path: '/',
    name: '首页',
    component: () => import(/* webpackChunkName: "page" */ '@/views/park'),
    redirect: '/home',
    children: [
      {
        path: '/home',
        name: 'Home',
        component: () =>
          import(/* webpackChunkName: "page" */ '@/views/park/home'),
        meta: {
          icon: '',
          title: '首页',
          screen: true
        }
      },
      {
        path: '/person',
        name: 'Person',
        component: () =>
          import(/* webpackChunkName: "page" */ '@/views/park/person'),
        meta: {
          icon: '',
          title: '人员',
          screen: true
        }
      },
      {
        path: '/car',
        name: 'Car',
        component: () =>
          import(/* webpackChunkName: "page" */ '@/views/park/car'),
        meta: {
          icon: '',
          title: '车辆',
          screen: true
        }
      },
      {
        path: '/device',
        name: 'Device',
        component: () =>
          import(/* webpackChunkName: "page" */ '@/views/park/device'),
        meta: {
          icon: '',
          title: '设备',
          screen: true
        }
      },

      {
        path: '/security',
        name: 'Security',
        component: () =>
          import(/* webpackChunkName: "page" */ '@/views/park/security'),
        meta: {
          icon: '',
          title: '安防',
          screen: true
        }
      },
      {
        path: '/firefighting',
        name: 'Firefighting',
        component: () =>
          import(/* webpackChunkName: "page" */ '@/views/park/firefighting'),
        meta: {
          icon: '',
          title: '消防',
          screen: true
        }
      },
      {
        path: '/energy',
        name: 'Energy',
        component: () =>
          import(/* webpackChunkName: "page" */ '@/views/park/energy'),
        meta: {
          icon: '',
          title: '能耗',
          screen: true
        }
      },
      {
        path: '/epidemic',
        name: 'Epidemic',
        component: () =>
          import(/* webpackChunkName: "page" */ '@/views/park/epidemic'),
        meta: {
          icon: '',
          title: '防疫',
          screen: true
        }
      },
      {
        path: '/environment',
        name: 'Environment',
        component: () =>
          import(/* webpackChunkName: "page" */ '@/views/park/environment'),
        meta: {
          icon: '',
          title: '环境',
          screen: true
        }
      },
      {
        path: '/cruise',
        name: 'Cruise',
        component: () =>
          import(/* webpackChunkName: "page" */ '@/views/park/cruise'),
        meta: {
          icon: '',
          title: '高空巡航',
          screen: true
        }
      },

      // 告警列表
      {
        path: '/alarm-result',
        name: 'AlarmResult',
        component: () =>
          import(/* webpackChunkName: "page" */ '@/views/park/alarm-result'),
        meta: {
          icon: '',
          title: '告警列表',
          screen: true
        }
      },

      // 车辆搜索列表
      {
        path: '/car-search-result',
        name: 'CarSearchResult',
        component: () =>
          import(
            /* webpackChunkName: "page" */ '@/views/park/car-search-result'
          ),
        meta: {
          icon: '',
          title: '车辆搜索',
          screen: true
        }
      },
      // 设备搜索列表
      {
        path: '/person-search-result',
        name: 'PersonSearchResult',
        component: () =>
          import(
            /* webpackChunkName: "page" */ '@/views/park/person-search-result'
          ),
        meta: {
          icon: '',
          title: '人员搜索',
          screen: true
        }
      },
      // 设备搜索列表
      {
        path: '/device-search-result',
        name: 'DeviceSearchResult',
        component: () =>
          import(
            /* webpackChunkName: "page" */ '@/views/park/device-search-result'
          ),
        meta: {
          icon: '',
          title: '设备搜索',
          screen: true
        }
      },
      // 安防搜索列表
      {
        path: '/security-search-result',
        name: 'SecuritySearchResult',
        component: () =>
          import(
            /* webpackChunkName: "page" */ '@/views/park/security-search-result'
          ),
        meta: {
          icon: '',
          title: '安防搜索',
          screen: true
        }
      },
      // 安防监控列表
      {
        path: '/security-monitor',
        name: 'securityMonitor',
        component: () =>
          import(
            /* webpackChunkName: "page" */ '@/views/park/security-monitor'
          ),
        meta: {
          icon: '',
          title: '安防监控列表',
          screen: true
        }
      },
      // 消防搜索列表
      {
        path: '/firefighting-search-result',
        name: 'FirefightingSearchResult',
        component: () =>
          import(
            /* webpackChunkName: "page" */ '@/views/park/firefighting-search-result'
          ),
        meta: {
          icon: '',
          title: '安防搜索',
          screen: true
        }
      },
      // 消防监控列表
      {
        path: '/firefighting-monitor',
        name: 'firefightingMonitor',
        component: () =>
          import(
            /* webpackChunkName: "page" */ '@/views/park/firefighting-monitor'
          ),
        meta: {
          icon: '',
          title: '消防监控列表',
          screen: true
        }
      },

      // 能耗搜索结果
      {
        path: '/energy-search-result',
        name: 'EnergySearchResult',
        component: () =>
          import(
            /* webpackChunkName: "page" */ '@/views/park/energy-search-result'
          ),
        meta: {
          icon: '',
          title: '能耗搜索',
          screen: true
        }
      },
      // 环境搜索结果
      {
        path: '/environment-search-result',
        name: 'EnvironmentSearchResult',
        component: () =>
          import(
            /* webpackChunkName: "page" */ '@/views/park/environment-search-result'
          ),
        meta: {
          icon: '',
          title: '环境搜索',
          screen: true
        }
      }
    ],
    meta: {}
  }
]
